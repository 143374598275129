import {Link, useLocation} from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector  } from "react-redux";
import { createUser, fetchUserInfo } from "../../actions/user";
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../../assets/logo.svg';
import { sendEvent, Event } from '../../util/ga';
import GoogleAuth from './google-auth';

const SignUp = (props)  => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const userInfo = useSelector(state => state.user);

  const disabledBtnAttr = {disabled: isLoading}

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const googleAuthEnabled = () => {
    return query.get('google-auth-enabled') ? true : false;
  }

  const getRedirectPath = () => {
    let redirectURL = query.get('u');
    if(redirectURL == null) {
      return '/dashboard';
    }
    redirectURL = decodeURIComponent(redirectURL);
    return redirectURL;
  }

  useEffect(() =>{
    if(!userInfo.fetched) {
      dispatch(fetchUserInfo())
        .then(data => {
          setLoggedIn(true);
          setLoading(false);
          props.history.push(getRedirectPath());
        })
        .catch(err => {
          setLoggedIn(false);
          setLoading(false);
        })
    } else {
      setLoggedIn(userInfo.success);
      setLoading(false);
    }
    if(isLoggedIn) {
      props.history.push(getRedirectPath());
    }
  },[])

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    dispatch(createUser(name, email, password))
      .then(data => {
        props.history.push(getRedirectPath());
        sendEvent(Event.USER_SIGNED_UP, {});
      })
      .catch(e => {
        setLoading(false);
        if(e.response && e.response.data && e.response.data.error) {
          toast.error(e.response.data.error)
        } else if (e.message) {
          toast.error(e.message)
        }
      })
  }


  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <ToastContainer position="top-right" autoClose={5000} />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to='/'>
            <img
              className="mx-auto h-14 w-auto"
              src={Logo}
              alt="Mail Spy"
            />
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create your free account
            </h2>
          </Link>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleNameChange}
                    value={name}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={handleEmailChange}
                    value={email}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    minimumlength="8"
                    onChange={handlePasswordChange}
                    value={password}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="disabled:opacity-50 flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  {...disabledBtnAttr}
                  onClick={handleSubmit}
                >
                  Create my free account
                </button>
              </div>
            </form>

            {googleAuthEnabled() ? <div className="mt-10">
              <div className="relative">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-500">Or continue with</span>
                </div>
              </div>
              <div className="mt-4 w-full">
              <GoogleAuth text="signup_with" history={props.history} />
              </div>
            </div> : <div></div>}
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link to="/sign-in" className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Sign in now
            </Link>
          </p>
        </div>
      </div>
    </>
  )
}

export default SignUp;