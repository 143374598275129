import { useRef, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';


const DynamicHTMLRenderer = ({ html }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = '';
      const shadowContainer = document.createElement('div');
      const shadowRoot = shadowContainer.attachShadow({ mode: 'open' });
      const div = document.createElement('div');
      div.innerHTML = html;
      shadowRoot.appendChild(div);
      containerRef.current.appendChild(shadowContainer);
    }
  }, [html]);

  return <div ref={containerRef} />;
};

const ExtractedText = ({ html }) => {
  const [extractedText, setExtractedText] = useState('');

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || '';

    // Extract the first 100 characters
    let truncatedText = textContent.trim().slice(0, 100);

    if (textContent.length > 100) {
      truncatedText += "..."
    }

    setExtractedText(truncatedText);
  }, [html]);

  return (
    <div>
      <p className="truncate text-sm text-gray-500">{extractedText}</p>
    </div>
  );
};

function timeSince(date) {

  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (Math.floor(interval) === 1) {
    return Math.floor(interval) + " year";
  }
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (Math.floor(interval) === 1) {
    return Math.floor(interval) + " month";
  }
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (Math.floor(interval) === 1) {
    return Math.floor(interval) + " day";
  }
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (Math.floor(interval) === 1) {
    return Math.floor(interval) + " hour";
  }
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (Math.floor(interval) === 1) {
    return Math.floor(interval) + " minute";
  }
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

function formatDate(date) {
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  const minutes = date.getMinutes();
  const dayOfMonth = date.getDate();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${formattedHours}:${String(minutes).padStart(2, '0')}${ampm}, ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)} ${month} ${year}`;
}

function getOrdinalSuffix(dayOfMonth) {
  if (dayOfMonth >= 11 && dayOfMonth <= 13) {
      return 'th';
  }
  switch (dayOfMonth % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
  }
}


export default function InboxView(props) {
  const [currentMessageIdx, setCurrentMessageIdx] = useState(0)
  const messages = props.messages

  const messageClicked = (event) => {
    setCurrentMessageIdx(event.currentTarget.getAttribute('value'))
  }

  const MessageList = () => {
    let messageList = []
    for(let idx in messages) {
      const message = messages[idx]
      if(currentMessageIdx === idx ) {
        messageList.push(
          <span value={idx}  onClick={messageClicked} data-currentMessageIdx={currentMessageIdx} data-match={currentMessageIdx === idx} >
            <li
              key={message.idx}
              className={`bg-gray-200 hover:cursor-pointer relative bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 border-gray-900`}
            >
              
              <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="truncate text-sm  text-gray-500">{message.from}</p>
                    <p className="truncate text-m font-medium text-gray-900">{message.subject}</p>
                </div>
                <time
                  dateTime={message.sentTime}
                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                >
                  {timeSince(new Date(message.sentTime))} ago
                </time>
              </div>
              <div className="mt-1">
                <ExtractedText html={message.htmlBody} />
              </div>
            </li>
            <hr />
          </span>
        )
      } else {
        messageList.push(
          <span value={idx}  onClick={messageClicked} data-currentMessageIdx={currentMessageIdx} data-match={currentMessageIdx === idx} >
            <li
              key={message.idx}
              className={`hover:bg-gray-100 hover:cursor-pointer relative bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 border-gray-900`}
            >
              
              <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="truncate text-sm  text-gray-500">{message.from}</p>
                    <p className="truncate text-m font-medium text-gray-900">{message.subject}</p>
                </div>
                <time
                  dateTime={message.sentTime}
                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                >
                  {timeSince(new Date(message.sentTime))} ago
                </time>
              </div>
              <div className="mt-1">
                <ExtractedText html={message.htmlBody} />
              </div>
              
            </li>
            <hr />
          </span>
        )
      }
      
    }
    return messageList;
  }

  const getToEmail = () => {
    if(messages.length) {
      return "To: "  +props.mailBoxName+" <"+props.emailID+">"
    }

    return ""
  }


  const showCopiedToast = (event) => {
    toast.info("Email ID copied to clipboard")
  }

  const getMessageBody = () => {
    if (messages[currentMessageIdx]) {
      return <div className='h-screen p-2'><DynamicHTMLRenderer html={messages[currentMessageIdx].htmlBody} /></div>
    }

    return (
      <div>
        <div className="bg-gray-50 h-screen mt-24 px-12">
          <div className="max-w-max mx-auto">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">No Emails received yets 😔</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                  Add this inbox's email id in {props.mailBoxName} system to start receiving emails.
                  </p>
                </div>
                <CopyToClipboard text={props.emailID} onCopy={showCopiedToast}>
                <div className="mt-5">

                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    Copy Email ID
                  </button>
                </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          
          </div>
      </div>
    )
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full overflow-hidden">
        ```
      */}
      <div className="flex h-screen flex-col">
        <div className="flex min-h-0 flex-1 overflow-hidden">

          {/* Main area */}
          <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">
            <section
              aria-labelledby="message-heading"
              className="flex h-screen min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
            >
              {/* Top section */}
              <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                {/* Toolbar*/}
                <div className="flex h-16 flex-col justify-center">
                  <div className="px-4 sm:px-6 lg:px-8">
                    {getToEmail()}
                  </div>
                </div>
                {/* Message header */}
              </div>

              <div className="min-h-0 flex-1 overflow-y-auto">
                <div className="bg-white pb-6 pt-5 shadow">
                  <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
                    <div className="sm:w-0 sm:flex-1">
                      <h1 id="message-heading" className="text-lg font-medium text-gray-900">
                        {messages[currentMessageIdx] && messages[currentMessageIdx].subject}
                      </h1>
                      {messages[currentMessageIdx] && <p className="m÷t-1 truncate text-sm text-gray-500">{messages[currentMessageIdx].from}</p>}
                    </div>

                    <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
                      {messages[currentMessageIdx] &&<span className="inline-flex items-center rounded-full bg-cyan-100 px-3 py-0.5 text-sm font-medium text-cyan-800">
                        {formatDate(new Date(messages[currentMessageIdx].sentTime))}
                      </span>}
                    </div>
                  </div>
                </div>
                {getMessageBody()}
              </div>
            </section>

            {/* Message list*/}
            <aside className="hidden xl:order-first xl:block xl:flex-shrink-0 bg-gray-50 h-screen">
              <div className="relative flex h-screen w-96 flex-col border-r border-gray-200 bg-gray-100">
                <div className="flex-shrink-0">
                  <div className="flex h-16 flex-col justify-center bg-white px-6">
                    <div className="flex items-baseline space-x-3">
                      <h2 className="text-lg font-medium text-gray-900">Inbox</h2>
                      <p className="text-sm font-medium text-gray-500">{messages.length} messages</p>
                    </div>
                  </div>
                  <div className="border-b border-t border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
                    Sorted by date
                  </div>
                </div>
                <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
                  <ul className="divide-y divide-gray-200 border-b border-gray-200">
                    <MessageList />
                  </ul>
                </nav>
              </div>
            </aside>
          </main>
        </div>
      </div>
    </>
  )
}
