import MailboxService from '../services/MailboxService'
import { FETCH_ALL_MAILBOXES, CREATE_MAILBOX } from './types'

export const getAllMailBoxes = () => async (dispatch) => {
  try {
    const res = await MailboxService.getAllMailBoxes()

    dispatch({
      type: FETCH_ALL_MAILBOXES,
      payload: res
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}

export const createMailBox = (payload) => async (dispatch) => {
  try {
    const res = await MailboxService.createMailBox(payload)

    dispatch({
      type: CREATE_MAILBOX,
      payload: res
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
