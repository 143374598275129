import './App.css';
import NotFound from './modules/notfound';
import Landing from './modules/landing';
import Dashboard from './modules/dashboard';
import SignUp from './modules/registration/sign-up';
import SignIn from './modules/registration/sign-in';
import ProtectedRoute from './util/protected.route';
import MailBox from './modules/mailbox';
import PrivacyPolicy from './modules/legal/privacy_policy';
import TermsAndCondition from './modules/legal/terms_of_use';
import CookiePolicy from './modules/legal/cookie_policy';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/sign-in" component={SignIn} exact />
        <Route path="/sign-up" component={SignUp} exact />
        <ProtectedRoute path="/dashboard" component={Dashboard} exact />
        <ProtectedRoute exact path="/dashboard/mailbox/:mailboxid" component={MailBox} />
        <Route path="/legal/privacy-policy" component={PrivacyPolicy} exact />
        <Route path="/legal/terms-and-conditions" component={TermsAndCondition} exact />
        <Route path="/legal/cookie-policy" component={CookiePolicy} exact />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
