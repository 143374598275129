import EmailService from "../../../services/EmailService"
import { useEffect, useState } from "react"
import Loader from "../../common/loader"
import InboxView from "./inbox_view"

const Inbox = (props) => {
  const mailBoxID = props.mailBoxID

  const [isLoading, setLoading] = useState(true);
  const [emails, setEmails] = useState([])
  const [emailID, setEmailID] = useState(null)
  const [mailBoxName, setMailBoxName] = useState(null)

  useEffect(() =>{
    EmailService.getAllEmails(mailBoxID).then(res => {
      setLoading(false)
      setEmails(res.data.emails)
      setMailBoxName(res.data.name)
      setEmailID(res.data.emailID)
    }).catch(err => {
      setLoading(false)
    })
  },[])

  return (
    isLoading ? <Loader /> : <div>
      <div className="mt-0">
        <div><InboxView messages={emails} emailID={emailID} mailBoxName={mailBoxName} /></div>
       
      </div>
      </div>
  )
}

export default Inbox;
