import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="mt-12 border-t border-gray-200 pt-8 pb-8 mx-auto max-w-7xl flex flex-col md:flex-row md:justify-between items-center">
      <p className="text-base text-gray-400 mb-4 md:mb-0">© 2024 MailSpy. All rights reserved.</p>
      <div className="space-y-4 md:space-y-0 md:space-x-4">
        <Link to="/legal/privacy-policy" className="text-base text-gray-400 hover:text-gray-600 p-1">
          Privacy Policy
        </Link>
        <Link to="/legal/terms-and-conditions" className="text-base text-gray-400 hover:text-gray-600 p-1">
          Terms & Conditions
        </Link>
        <Link to="/legal/cookie-policy" className="text-base text-gray-400 hover:text-gray-600 p-1">
          Cookie Policy
        </Link>
      </div>
    </div>
  );
}
