const Loader = (props) => {
  let mainClass="flex h-screen"
  if(props.componentLoader) {
    mainClass = "flex"
  }
  return (
    <div className={mainClass}>
      <div className="m-auto flex justify-center items-center">
        <div className="animate-spin rounded-full h-40 w-40 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    </div>
  )
}

export default Loader
