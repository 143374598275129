import { BarChart } from '@tremor/react';

const dataFormatter = (number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

export function EmailPerDayVolume(props) {
  let analyticsData = props.analyticsData;

  return (
    <div className="bg-white max-w-2xl border-2 rounded-2xl p-4">
      <div className="text-lg font-medium text-gray-500 mb-4 text-center">
        Total Emails By Day of the Week
      </div>
      <div className="flex items-center justify-center">
        <BarChart
          className="mt-6"
          data={analyticsData.email.volume.per_day}
          index="name"
          categories={["count"]}
          colors={['blue']}
          valueFormatter={dataFormatter}
          yAxisWidth={48}
          showAnimation={true}
          showLegend={false}
        />
      </div>
    </div>
  );
}