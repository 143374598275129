import Avatar from 'react-avatar';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';

const MailBoxTile = (props) => {

  const showCopiedToast = (event) => {
    toast.info("Email ID copied to clipboard")
  }

  return (
    <div className="container">
       {/* <Link to={"/dashboard/form/" + props.formId} className="a-not-formatting"> */}
       <div
          className="w-640 col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <Link to={"/dashboard/mailbox/" + props.id} className="a-not-formatting">
            <div className="flex-1 flex flex-col p-8">
              <div className="flex flex-row">
                <Avatar className="w-24 h-24 my-2 rounded-full" size={40} name={props.name} />
                <div className='mt-2 mx-4'>
                  <div className="text-gray-900 text-2xl font-medium text-left">{props.name}</div>
                  <div className="py-1 text-xs font-medium rounded-full text-left">
                    <p className="text-ellipsis overflow-hidden">{props.email}</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </Link>
          <CopyToClipboard text={props.email} onCopy={showCopiedToast}>
            <div className="-mt-px flex divide-x divide-gray-200 cursor-pointer hover:bg-teal-50">
              <div className="w-0 flex-1 flex">
                <div
                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                >
                  <span className="ml-3 cursor-pointer">Copy Email ID</span>
                </div>
              </div>
            </div>
          </CopyToClipboard>
        </div>
      {/* </Link> */}
    </div>
  )
}

export default MailBoxTile
