import { DonutChart, Legend, Card } from '@tremor/react';

const valueFormatter = (number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

export function SenderVolume(props) {
  let analyticsData = props.analyticsData;

  return (
    // <div className="bg-white max-w-lg border-2 rounded-2xl p-4">
    <Card decoration="top" decorationColor="blue">
      <div className="text-lg font-medium text-gray-500 mb-4 text-center">
        Sender Email ID Distribution
      </div>
      <div className="flex items-center justify-center">
        <DonutChart
          data={analyticsData.email.volume.per_sender}
          category="count"
          index="name"
          valueFormatter={valueFormatter}
          showAnimation={true}
        />
        {/* <Legend
          categories={['noreply@marketing.com', 'noreply@mail.something.com']}
          className="max-w-xs"
        /> */}

      </div>
    </Card>
  );
}