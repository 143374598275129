import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useDispatch  } from "react-redux";
import { googleAuth } from "../../actions/user";
import { toast } from 'react-toastify';


export default function GoogleAuth(props) {
  const dispatch = useDispatch();

  const onAuthSuccess = (credential) => {
    dispatch(googleAuth(credential))
      .then(data => {
        props.history.push("/dashboard");
      })
      .catch(e => {
        if(e.response && e.response.data && e.response.data.error) {
          toast.error(e.response.data.error)
        } else if (e.message) {
          toast.error(e.message)
        }
      })
  }

  return (
    <GoogleOAuthProvider clientId="157124007433-91kvet21gkhjdl9s22j646pfca34mf2j.apps.googleusercontent.com">
      <div className="flex justify-center items-center">
        <div className="w-full max-w-64">
          <GoogleLogin
            text={props.text}
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
              onAuthSuccess(credentialResponse.credential);
            }}
            onError={() => {
              toast.error("Login failed. Please try again after sometime.")
            }}
          />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}
