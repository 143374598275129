import { SenderVolume } from "./slices/sender_volume";
import { EmailPerDayVolume } from "./slices/email_per_day_volume";
import { EmailPerHourVolume } from "./slices/email_per_hour_volume";
import { TotalEmails } from "./slices/total_emails";
import WordCloudComponent from "./slices/word_cloud";
import TopLinks from "./slices/top_links";
import { useEffect, useState } from "react"
import MailboxService from "../../services/MailboxService";
import { toast } from 'react-toastify';
import Loader from "../common/loader";
import TopImageURLs from "./slices/top_image_urls";
import { TotalEmailsWithAttachments } from "./slices/total_emails_with_attachments";
import { SubjectAvgWords } from "./slices/subject_avg_words";
import { SubjectAvgLength } from "./slices/subject_avg_length";
import Datepicker from "react-tailwindcss-datepicker"; 
import { sendEvent, Event } from "../../util/ga";

const Analytics = (props) => {
  const mailBoxID = props.mailBoxID

  const [isLoading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState({})

  let endTime = new Date()
  let startTime = new Date();
  startTime.setDate(startTime.getDate() - 60);

  const START_FROM = new Date();
  START_FROM.setMonth(START_FROM.getMonth() - 1);

  const [value, setValue] = useState({ 
    startDate: startTime,
    endDate: endTime
  });

  const dateTimeValueChanged = (value) => {
    setValue(value)
    setLoading(true)
    getAnalytics(value)
  }

  const getAnalytics = (data) => {
    let startDate = new Date(data.startDate);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(data.endDate);
    endDate.setHours(23, 59, 59, 999);

    MailboxService.getAnalytics(mailBoxID, startDate, endDate).then(res => {
      setLoading(false)
      setAnalytics(res.data)
      sendEvent(Event.ANALYTICS_PAGE_LOADED, {mailboxId: data.mail_box_id});
    }).catch(e => {
      setLoading(false)
      if(e.response && e.response.data && e.response.data.error) {
        toast.error(e.response.data.error)
      } else {
        toast.error("Something went wrong. Please try again after some time.")
      }
    })
  }

  useEffect(() =>{
    getAnalytics(value)
  },[])

  const AnalyticsUI = () => {
    return (
      <div className="bg-gray-50">
        <div className="float-right p-4 mt-4">
          <div className="w-96">
            <Datepicker 
              value={value}
              onChange={dateTimeValueChanged}
              showShortcuts={true}
              startFrom={START_FROM}
            />
          </div>
        </div>
        <br/><br/><br/>
        <div className="max-w-7xl mx-auto mt-4">
          <div class="container mx-auto p-4">
            <div class="flex flex-wrap -mx-2">
              <div class="w-full sm:w-1/2 lg:w-1/3 px-2 mb-4">
              <div className="mb-4"><TotalEmails count={analytics.email.total} /></div>
                <TotalEmailsWithAttachments count={analytics.email.total_emails_with_attachments} />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/3 px-2 mb-4">
                <div className="mb-4"><SubjectAvgWords count={analytics.email.avg_words_in_subject} /></div>
                <SubjectAvgLength count={analytics.email.avg_subject_lengths} />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/3 px-2 mb-4">
                <SenderVolume analyticsData={analytics} />
              </div>
            </div>
          </div>
          <div class="container mx-auto p-4">
            <div class="flex flex-wrap -mx-2">
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <EmailPerDayVolume analyticsData={analytics} />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <EmailPerHourVolume analyticsData={analytics} />
              </div>
            </div>
          </div>
          <div class="container mx-auto p-4">
            <div class="flex flex-wrap -mx-2">
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <TopLinks topLinks={analytics.email.top_links} />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <TopImageURLs topLinks={analytics.email.top_image_urls} />
              </div>
            </div>
          </div>
          <div class="container mx-auto p-4">
            <div class="flex flex-wrap -mx-2">
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <WordCloudComponent analyticsData={analytics} />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 px-2 mb-4">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    isLoading ? <Loader /> : <div><AnalyticsUI /></div>
  )
}

export default Analytics;
