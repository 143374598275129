import MarkdownUI from '../common/markdown'
import TermsAndConditionContent from './docs/terms-of-use.md'
import NavBar from '../landing/nav_bar'
import Footer from '../landing/footer'

export default function TermsAndCondition() {
  return (
    <div>
      <NavBar />
      <div className="p-4 m-auto max-w-7xl mt-24">
        <MarkdownUI file={TermsAndConditionContent} />
      </div>
      <Footer />
    </div>
  )
}
