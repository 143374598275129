import http from '../util/http-common';

const getAllEmails = (mailBoxId) => {
  return http.get("/v1/mailboxes/" + mailBoxId + "/mails")
}

const EmailService = {
  getAllEmails,
}

export default EmailService
