import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMailBoxes } from "../../actions/mailbox";
import Loader from "../common/loader";
import EmptyDashboard from "./slices/no_mailbox";
import MailBoxTile from "./slices/mailbox_tile";
import NewMailBox from "./slices/new_mailbox";
import NavBar from "../common/navbar";
import { ToastContainer } from 'react-toastify';

const Dashboard = (props) => {
  const dispatch = useDispatch()
  const allMailBoxes = useSelector(state => state.allMailBoxInfo.data)

  const [isLoading, setLoading] = useState(true)
  const [noMailBoxExists, setNoMailBox] = useState(false)

  useEffect(() => {
    dispatch(getAllMailBoxes()).then(data => {
      if(data.length === 0) {
        setNoMailBox(true)
      }

      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  },[])

  const getMailBoxTilesUI = () => {
    let mailBoxTilesUI = []
    for(let idx in allMailBoxes) {
      mailBoxTilesUI.push(
        <MailBoxTile
          key={allMailBoxes[idx].id}
          id={allMailBoxes[idx].id}
          name={allMailBoxes[idx].name}
          email={allMailBoxes[idx].emailID}
        />
      )
    }
    return (
      <div className="grid grid-cols-4 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {mailBoxTilesUI}
      </div>
    )
  }

  const getDashboardUI = () => {
    return (
      <div className="bg-gray-50 h-screen">
        <NavBar />
        {/* <Header history={props.history} /> */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-2">
          <div className="float-right">
            <NewMailBox history={props.history} />
          </div>
          <br/><br/><br/><br/><br />
          {noMailBoxExists && <div className="mt-8"><EmptyDashboard /></div>}
          {!noMailBoxExists && getMailBoxTilesUI() }
        </div>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} />
      {isLoading && <Loader />}
      {!isLoading && getDashboardUI()}
    </div>
  )
}

export default Dashboard;