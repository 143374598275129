import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import ReactDOM from 'react-dom/client';
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_ENABLE_GA) {
    ReactGA.initialize("G-9PPZZHTSMX");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
    <App />  
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
