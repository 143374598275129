import ReactGA from "react-ga4";

export const sendEvent = (eventName, props) => {
  ReactGA.event({
    userId: 'unknown',
    groupID: 'unknown',
    ...props
  });  
}

export const Event = {
  LANDING_PAGE_LOADED: "LANDING_PAGE_LOADED",
  SIGNIN_PAGE_LOADED: "SIGNIN_PAGE_LOADED",
  SIGNUP_PAGE_LOADED: "SIGNUP_PAGE_LOADED",
  USER_SIGNED_UP: "USER_SIGNED_UP",
  USER_SIGNED_IN: "USER_SIGNED_IN",
  DASHBOARD_LOADED: "DASHBOARD_LOADED",
  CREATE_NEW_MAILBOX_CLICKED: "CREATE_NEW_MAILBOX_CLICKED",
  MAILBOX_CREATED: "MAILBOX_CREATED",
  ANALYTICS_PAGE_LOADED: "ANALYTICS_PAGE_LOADED",
  INBOX_OPENED: "INBOX_OPENED",
}
