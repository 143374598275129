import { FETCH_ALL_MAILBOXES } from "../actions/types";

const initialSate = {
  success: false,
  fetched: false,
  data: []
}

const allMailBoxInfoReducer = (allMailBoxInfo = initialSate, action) => {
  const {type, payload} = action;

  switch(type) {
      case FETCH_ALL_MAILBOXES:
        return {
          success: payload.success,
          data: payload.data,
          fetched: true
        }
      default:
        return allMailBoxInfo         
  }
}

export default allMailBoxInfoReducer;
