import http from '../util/http-common';

const createUser = (payload) => {
  return http.post("/v1/users/sign-up", payload)
}

const loginUser = (payload) => {
  return http.post("/v1/users/sign-in", payload)
}

const userInfo = () => {
  return http.get("/v1/users/me")
}

const googleAuth = (id_token) => {
  return http.post("/v1/auth/google", {
    id_token: id_token,
  })
}

const UserService = {
  createUser,
  loginUser,
  userInfo,
  googleAuth,
}

export default UserService
