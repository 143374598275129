import axios from "axios";
import { API_URL, AUTH_COOKIE_NAME } from './constants';
import { getCookie } from './cookie'

let baseURL = API_URL;

export default axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
    "X-Auth-Token": getCookie(AUTH_COOKIE_NAME)
  },
  withCredentials: false
});
