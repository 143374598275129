import NavBar from "../common/navbar";
import Tabs from "./slices/tabs";
import { ToastContainer } from 'react-toastify';

const MailBox = (props) => {
  const mailBoxID = props.match.params.mailboxid
  return (
    <div className="bg-gray-50">
      <ToastContainer position="top-right" autoClose={5000} />
      <NavBar />
      <div className="max-w-7xl mx-auto mt-4">
        <Tabs mailBoxID={mailBoxID} />
      </div> 
    </div>
  )
}

export default MailBox;
