import http from '../util/http-common';

const getAllMailBoxes = () => {
  return http.get("/v1/mailboxes")
}

const createMailBox = (payload) => {
  return http.post("/v1/mailboxes", payload)
}

const getAllEmails = (mailBoxId) => {
  return http.get("/v1/mailboxes/" + mailBoxId + "/mails")
}

const getAnalytics = (mailBoxId, startDate, endDate) => {
  return http.get("/v1/mailboxes/" + mailBoxId + "/analytics", {params: {
    start_time: startDate,
    end_time: endDate,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }})
}

const MailboxService = {
  getAllMailBoxes,
  createMailBox,
  getAllEmails,
  getAnalytics,
}

export default MailboxService
