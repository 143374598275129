import { ArrowTrendingUpIcon, Cog6ToothIcon, InboxIcon } from '@heroicons/react/20/solid'
import { useState } from "react"
import Inbox from './inbox'
import ComingSoon from '../../common/comingsoon'
import Analytics from '../../analytics'

const tabs = [
  { name: 'Inbox', href: '/inbox', icon: InboxIcon },
  { name: 'Analytics', href: '/analytics', icon: ArrowTrendingUpIcon },
  // { name: 'Settings', href: '/settings', icon: Cog6ToothIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Tabs = (props) => {
  const mailBoxID = props.mailBoxID

  const [currentTab, setCurrentTab] = useState(tabs[0].name)
  
  const tabChanged = (event) => {
    event.preventDefault()
    const t = event.target.getAttribute('name')
    if(t) {
      setCurrentTab(event.target.getAttribute('name'))
    }
  }

  const tabUI = () => {
    if(currentTab === tabs[0].name) {
      return (
        <div>
          <Inbox mailBoxID={mailBoxID} />
        </div>
      )
    } else if(currentTab === tabs[1].name) {
      return (
        <div>
          <Analytics mailBoxID={mailBoxID} />
        </div>
      )
    } else {
      return (
        <div>
          <ComingSoon />
        </div>
      )
    }
  }

  const tabOptionChanged = (event) => {
    setCurrentTab(event.target.value)
  }

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          onChange={tabOptionChanged}
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          value={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
             {tabs.map((tab) => (
               <a
                key={tab.name}
                href={window.location.pathname + tab.href}
                name={tab.name}
                onClick={tabChanged}
                className={classNames(
                  (currentTab === tab.name)
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'w-1/2 inline-flex justify-center border-b-2 py-4 px-0 text-center text-sm font-medium' // TODO w-1/3 if enable settings
                )}
                aria-current={(currentTab === tab.name) ? 'page' : undefined}
               >
                 <tab.icon
                   className={classNames(
                    (currentTab === tab.name) ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                     '-ml-0.5 mr-2 h-5 w-5'
                   )}
                   aria-hidden="true"
                 />
                 {tab.name}
               </a>
             ))}
           </nav>
        </div>
      </div>
      {tabUI()}
    </div>
  )
}

export default Tabs