import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  Route,
} from "react-router-dom";import { fetchUserInfo } from "../actions/user";

const ProtectedRoute = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const userInfo = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfo.fetched) {
      dispatch(fetchUserInfo())
        .then((data) => {
          setLoggedIn(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoggedIn(false);
          setLoading(false);
        });
    } else {
      setLoggedIn(userInfo.success);
      setLoading(false);
    }
  }, [userInfo, dispatch]);

  if (isLoading) {
    return null;
  }

  return isLoading ? null :
  isLoggedIn ?
    <Route path={props.path} component={props.component} exact={props.exact}/> :
    window.location = '/sign-in?u=' + encodeURIComponent(props.path)

};

export default ProtectedRoute;
