
export default function TopLinks(props) {
  const data = props.topLinks;
  return (
    <div className="px-4 sm:px-6 lg:px-8 bg-white border-2 rounded-2xl p-4">
      <div className="text-lg font-medium text-gray-500 mb-4 text-center">
        Top Links
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    URL
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Count
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 lg:pl-8 text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  data.map((link) => (
                    <tr key={link.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        <a href={link.name}>{link.name.length > 40 ? `${link.name.slice(0, 40)}...` : link.name}</a>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{link.count}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
