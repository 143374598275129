import MarkdownUI from '../common/markdown'
import CookiePolicyContent from './docs/cookie-policy.md'
import NavBar from '../landing/nav_bar'
import Footer from '../landing/footer'

export default function CookiePolicy() {
  return (
    <div>
      <NavBar />
      <div className="p-4 m-auto max-w-7xl mt-24">
        <MarkdownUI file={CookiePolicyContent} />
      </div>
      <Footer />
    </div>
  )
}
