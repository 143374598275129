import {
  FETCH_USER_INFO, LOGOUT_USER, LOGIN_USER
} from "../actions/types";

const initialState = {
  fetched: false,
  success: false,
  data: {}
};

const userReducer = (user = initialState, action) => {
  const {type, payload} = action;

  switch(type) {
    case LOGIN_USER:
      return initialState
    case FETCH_USER_INFO:
      return {
        fetched: true,
        success: payload.success,
        data: payload.data
      }
    case LOGOUT_USER:
      return initialState
    default:
      return user
  }
}

export default userReducer;
