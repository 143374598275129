import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useState, useCallback} from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { createMailBox } from '../../../actions/mailbox';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CheckIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import CopyToClipboard from 'react-copy-to-clipboard';
import { sendEvent, Event } from '../../../util/ga';

const NewMailBox = (props) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [mailboxEmailId, setMailBoxEmailId] = useState('');
  const [mailboxId, setMailBoxId] = useState('');
  const [showMailBoxId, setShowMailBoxId] = useState(false);

  const showCopiedToast = (event) => {
    toast.info("Email ID copied to clipboard")
  }

  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleCreateMailbox = useCallback(() => {
    setLoading(true);
    const payload = { name };
    dispatch(createMailBox(payload))
      .then((data) => {
        setMailBoxEmailId(data.emailID);
        setMailBoxId(data.id);
        setShowMailBoxId(true);
        setLoading(false);
        sendEvent(Event.MAILBOX_CREATED, {mailboxId: data.id});
      })
      .catch((e) => {
        setLoading(false);
        if (e.response && e.response.data && e.response.data.error) {
          toast.error(e.response.data.error);
        } else {
          toast.error('Something went wrong. Please try again after some time.');
        }
      });
  }, [name, dispatch]);

  const MailBoxId = React.memo(() => (
    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
        </div>
        <div className="mt-3 sm:mt-5">
          <DialogTitle as="h3" className="text-base text-center font-semibold leading-6 text-gray-900">
            Email Inbox Created Successfully
          </DialogTitle>
          <div className="mt-2">
            <p className="text-sm text-gray-500 text-center">
            Please add this email address to the competitor system so it can receive all emails. This will allow us to monitor their emails.
            </p>
            <CopyToClipboard text={mailboxEmailId} onCopy={showCopiedToast}>
            <div className="bg-gray-50 p-1 rounded-lg shadow-md px-2 mt-4">
              <div className="flex items-center justify-between">
                <p className="text-gray-500 text-sm">{mailboxEmailId}</p>
                <button
                  id="copyButton"
                  className="flex items-center justify-center p-1 w-8 h-8 text-emerald-600 hover:text-emerald-800 focus:outline-none"
                >
                  <ClipboardDocumentListIcon className="w-6 h-6" />
                </button>
              </div>
            </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 flex">
        <button
          type="button"
          onClick={() => {
            setShowMailBoxId(false)
            setName('')
            setMailBoxEmailId('')
            setMailBoxId('')
          }}
          className="m-2 inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          Create Another
        </button>
        <button
          type="button"
          onClick={() => props.history.push('/dashboard/mailbox/' + mailboxId)}
          className="m-2 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          Go to Inbox
        </button>
      </div>
    </DialogPanel>
  ), [mailboxEmailId, mailboxId, props.history]);

  const CreateMailBox = React.memo(() => (
    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
      <div>
        <div className="mt-3 sm:mt-5">
          <DialogTitle as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
            Create a dedicated mailbox for tracking your competitor
          </DialogTitle>
          <div className="mt-2">
            <label htmlFor="email" className="required mt-4 block text-sm font-medium text-gray-700">
              Name
            </label>
            <div className="mt-1 mb-4">
              <input
                type="text"
                value={name}
                autoFocus
                onChange={handleNameChange}
                required
                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-5 sm:mt-6">
        <button
          type="button"
          className="flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={handleCreateMailbox}
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create'}
        </button>
      </div>
    </DialogPanel>
  ), [name, handleNameChange, handleCreateMailbox, isLoading]);

  return (
    <div className="mt-6">
      <button
        type="button"
        onClick={openModal}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        New MailBox
      </button>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" autoFocus>
            {showMailBoxId ? <MailBoxId /> : <CreateMailBox />}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default NewMailBox;
