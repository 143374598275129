import {
  FETCH_USER_INFO,
  LOGIN_USER,
  LOGOUT_USER
} from "./types";
import {setCookie,eraseCookie} from "../util/cookie";
import { AUTH_COOKIE_NAME } from "../util/constants";
import UserService from "../services/UserService";

export const loginUser = (email, password) => async (dispatch) => {
  try {
    const res = await UserService.loginUser({email: email, password: password})

    setCookie(AUTH_COOKIE_NAME, res.data.token)

    dispatch({
      type: LOGIN_USER,
      payload: {
        success: true,
        data: res.data
      }
    })

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: LOGIN_USER,
      payload: {
        success: false,
        data: err
      }
    })
    return Promise.reject(err)
  }
};

export const createUser = (name, email, password) => async (dispatch) => {
  try {
    const res = await UserService.createUser({name: name, email: email, password: password})

    setCookie(AUTH_COOKIE_NAME, res.data.token)

    dispatch({
      type: LOGIN_USER,
      payload: {
        success: true,
        data: res.data
      }
    })

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: LOGIN_USER,
      payload: {
        success: false,
        data: err
      }
    })
    return Promise.reject(err)
  }
};

export const googleAuth = (credential) => async (dispatch) => {
  try {
    const res = await UserService.googleAuth(credential)

    setCookie(AUTH_COOKIE_NAME, res.data.token)

    dispatch({
      type: LOGIN_USER,
      payload: {
        success: true,
        data: res.data
      }
    })

    return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: LOGIN_USER,
      payload: {
        success: false,
        data: err
      }
    })
    return Promise.reject(err)
  }
};

export const logOutUser = () => async (dispatch) => {
  try {
    eraseCookie(AUTH_COOKIE_NAME)

    dispatch({
      type: LOGOUT_USER,
      payload: {
        success: true,
        data: {}
      }
    })

    return Promise.resolve();
  } catch (err) {
    dispatch({
      type: LOGOUT_USER,
      payload: {
        success: false,
        data: err
      }
    })
    return Promise.reject(err)
  }
};

export const fetchUserInfo = () => async (dispatch) => {
  try {
    const res = await UserService.userInfo()
    dispatch({
      type: FETCH_USER_INFO,
      payload: {
        success: true,
        data: res.data,
      }
    })
    return Promise.resolve(res.data)
  } catch (err) {
    dispatch({
      type: FETCH_USER_INFO,
      payload: {
        success: false,
        data: err
      }
    })
    return Promise.reject(err)
  }
};