import { Card } from '@tremor/react';

export function SubjectAvgWords(props) {
  let count = props.count;
  return (
    <Card
      decoration="top"
      decorationColor="blue"
    >
      <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Avg Words in Subject</p>
      <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold mt-2">{count.toFixed(2)}</p>
    </Card>
  );
}
