import {
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  ScaleIcon,
} from '@heroicons/react/24/outline'
import CreateBoxImg from '../../assets/create-inbox.png';
import ShareEmailImg from '../../assets/share-email.png';
import InboxImg from '../../assets/inbox.png';
import AnalyticsImg from '../../assets/analytics_screenshot.png';

export default function Working() {
  return (
    <div className="overflow-hidden bg-white py-16 lg:py-24">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">

        <div className="relative">
          <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            How it works?
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
          With just four simple steps, you can start monitoring your competitors' email marketing strategies.</p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Create Inbox</h3>
            <p className="mt-3 text-lg text-gray-500">
            Set up a dedicated inbox for each competitor. This will be a unique email address where you can receive all emails from that specific competitor.</p>
          </div>

          <div aria-hidden="true" className="relative -mx-4 mt-10 lg:mt-0">
            <img
              alt="Create Inbox"
              src={CreateBoxImg}
              width={490}
              className="relative mx-auto"
            />
          </div>
        </div>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Add Email In Competitor’s System</h3>
              <p className="mt-3 text-lg text-gray-500">
              For every inbox you create, you'll receive a unique email address. Use this email address to subscribe to your competitor's marketing lists using different lead magnets.</p>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <img
                alt="Share Email Id"
                src={ShareEmailImg}
                width={490}
                className="relative mx-auto"
              />
            </div>
          </div>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Start Receiving Emails</h3>
            <p className="mt-3 text-lg text-gray-500">
            Check your inbox to view all emails sent by a specific competitor. Each inbox is isolated, allowing you to focus on the emails from one competitor at a time.</p>
          </div>

          <div aria-hidden="true" className="relative -mx-4 mt-10 lg:mt-0">
            <img
              alt="Inbox View"
              src={InboxImg}
              width={490}
              className="relative mx-auto"
            />
          </div>
        </div>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Leverage Analytics</h3>
              <p className="mt-3 text-lg text-gray-500">
              Analyze your competitors' email patterns with our comprehensive analytics. Gain valuable insights with metrics on image usage, link frequency, emoji count, and daily email volume.</p>
            </div>
            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <img
                alt="Analytics View"
                src={AnalyticsImg}
                width={490}
                className="relative mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
