import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import { useEffect, useState } from 'react';

export default function MarkdownUI(props) {
  let file = props.file;
  const [content, setContent] = useState('');
  useEffect(() => {
    // Fetch the Markdown file
    fetch(file)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      })
      .catch((error) => console.error('Error fetching the Markdown file:', error));
  }, []);

  return (
    <div className="prose max-w-full">
      <Markdown 
        children={content} 
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSlug]} />
    </div>
  )
}
