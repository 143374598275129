import WordCloud from 'react-d3-cloud';

// List of common stop words
const stopWords = [
  'a', 'an', 'the', 'and', 'or', 'but', 'if', 'then', 'else', 'when', 'at', 'by', 'for', 
  'with', 'about', 'against', 'between', 'into', 'through', 'during', 'before', 'after', 
  'above', 'below', 'to', 'from', 'up', 'down', 'in', 'out', 'on', 'off', 'over', 'under', 
  'again', 'further', 'then', 'once', 'here', 'there', 'all', 'any', 'both', 'each', 'few', 
  'more', 'most', 'other', 'some', 'such', 'no', 'nor', 'not', 'only', 'own', 'same', 'so', 
  'than', 'too', 'very', 's', 't', 'can', 'will', 'just', 'don', 'should', 'now', 'your', 
  'you', 'is', 'are', 'was', 'were', 'be', 'been', 'being', 'have', 'has', 'had', 'do',
  'does', 'did', 'but', 'at', 'by', 'with', 'from', 'here', 'when', 'where', 'how', 'all',
  'any', 'both', 'each', 'few', 'more', 'some', 'such', 'only', 'own', 'same', 'so', 'than',
  'too', 'very', 's', 't', 'can', 'will', 'just', 'don', 'should', 'now', 'd', 'll', 'm',
  'o', 're', 've', 'y', 'ain', 'aren', 'couldn', 'didn', 'doesn', 'hadn', 'hasn', 'haven',
  'isn', 'ma', 'mightn', 'mustn', 'needn', 'shan', 'shouldn', 'wasn', 'weren', 'won', 'wouldn',
  '[Ad]', '[Sponsored]', '[Promotion]', '[Advertisement]', '[Promotional]', '[Promo]', '[Advert]',
  'this', 'that', 'these', 'those', 'am', 'is', 'are', 'was', 'were', 'be', 'been', 'being',
  'To:', 'From:', 'Subject:', 'Re:', 'Sent:', 'Cc:', 'Bcc:', 'Fwd:', 'Fw:', 'www', 'com', 'http',
  'https', 'html', 'org', 'net', 'co', 'uk', 'gmail', 'yahoo', 'hotmail', 'outlook', 'email',
  'we', 'our', 'us', 'you', 'your', 'yours', 'he', 'him', 'his', 'she', 'her', 'hers', 'it',
  'its', 'they', 'them', 'their', 'theirs', 'what', 'which', 'who', 'whom', 'whose', 'my', 'mine'
];

const data = [
  { text: 'Hey', value: 1000 },
  { text: 'lol', value: 200 },
  { text: 'first impression', value: 800 },
  { text: 'very cool', value: 1000000 },
  { text: 'duck', value: 10 },
];

const getFontBaseValue = (data) => {
  let maxValue = 1;
  for (let item in data) {
    if (data[item].value > maxValue) {
      maxValue = data[item].value;
    }
  }

  return 80 / maxValue;
};

// Updated function to filter out stop words
const transformToWordCloudInput = (data) => {
  let retData = [];
  for (let item in data) {
    const word = data[item].name.toLowerCase();
    if (!stopWords.includes(word)) { // Filter out stop words
      retData.push({
        text: data[item].name,
        value: data[item].count
      });
    }
  }
  return retData;
};

const WordCloudComponent = (props) => {
  const analyticsData = props.analyticsData;
  const wordsData = transformToWordCloudInput(analyticsData.email.volume.words);

  return (
    <div className="bg-white border-2 rounded-2xl p-4">
      <div className="text-lg font-medium text-gray-500 mb-4 text-center">
        Word Cloud
      </div>
      <WordCloud 
        data={wordsData} 
        rotate={0} 
        padding={10} 
        fontSize={(word) => word.value * getFontBaseValue(wordsData)} 
      />
    </div>
  );
};

export default WordCloudComponent;